<div>
  <p>Proszę otworzyć aplikację na ekranie mobilnym</p>
</div>

<style>
  div {
    display: grid;
    height: 100%;
    justify-content: center;
  }
  p {
    justify-self: center;
    align-self: center;
    color: grey;
    font-size: 24px;
  }
</style>
