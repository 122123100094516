import App from './App.svelte';

const app = new App({
	target: document.body,
	props: {
		name: 'world'
	}
});

// //SERVICE WORKER - REJESTRACJA
// if ("serviceWorker" in navigator) {
// 	// Register Service Worker
// 	console.log("Registering service worker ...");

// 	navigator.serviceWorker
// 		.register("/service-worker.js")
// 		.then((reg) => console.log("Service Worker registered..."))
// 		.catch((err) => console.log("Service Worker NOT registered...", err));

// }

// MESSAGE='{"key": "OhiHCwzzJMA5jIBHG2JXLA", "message": {"from_email": "m.stefanczyk@cubicinch.pl", "subject": "Hello World", "text": "Welcome to Mailchimp Transactional!", "to": [{ "email": "michal@blesu.com", "type": "to" }]}}'

// curl -sS -X POST "https://mandrillapp.com/api/1.0/messages/send" --header 'Content-Type: application/json' --data-raw "$MESSAGE"

export default app;














