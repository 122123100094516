<script>
  import NonMobileInfoScreen from "./screens/NonMobileInfoScreen.svelte";
  let url = "";
  let isMobile = window.screen.width < 650;
  const newAppDownloadUrl = "http://bit.ly/tales-v2";
  const newAppManualUrl = "https://bit.ly/tales-v2-instalacja";
</script>

  {#if isMobile}
    <div class="task-full">
      <div class="frame-427318887">
        <div class="frame-33712">
          <img
            src="/images/image-content.svg"
            class="image-content"
            alt="image content"
          />
          <img
            src="/images/image-background.svg"
            class="image-background"
            alt="image background"
          />
        </div>
        <div class="frame-427318886">
          <span class="title">Pobierz nową wersję aplikacji</span>
          <section class="content">
            <p>
              Nowa wersja aplikacji Tales jest już dostępna. Pobierz ją aby
              nadal korzystać z systemu.
            </p>
            <p>
              Po zalogowaniu się w nowej aplikacji zobaczysz zadanie - wykonaj
              je, aby potwierdzić, że masz dostęp do aktualnej wersji.
            </p>
          </section>
        </div>
      </div>
      <div class="buttons">
        <a href={newAppDownloadUrl} class="button"> Pobierz nową wersję </a>
        <a href={newAppManualUrl} class="button button-secondary">
          Sprawdź instrukcję
        </a>
      </div>
    </div>
  {:else if !isMobile}
    <NonMobileInfoScreen />
  {/if}

<style>
  .task-full {
    display: flex;
    min-height: 100vh;
    width: 100vw;
    padding: 48px 12px 12px 12px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4px;
    border: 10px solid #e1e7ef;
    background: #fff;
  }
  .frame-427318887 {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }
  .button {
    display: flex;
    padding: 14px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    background: #192330;
    color: #fff;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
  .button-secondary {
    background-color: #fff;
    color: #192330;
    border: 2px solid #192330;
  }
  .frame-33712 {
    display: flex;
    /* width: 335px; */
    position: relative;
    /* height: 175px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .frame-427318886 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;

    /* from 85: */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }
  .image-background {
    width: 150px;
    height: 150px;
    flex-shrink: 0;
    left: 0px;
  }
  .image-content {
    width: 130.305px;
    height: 137.383px;
    position: absolute;
    /* left: 97px; */
    left: 0px;
    /* top: 31px; */
  }
  .title {
    color: #000b15;
    font-feature-settings: "clig" off, "liga" off;

    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
  }
  p {
    color: #606f7e;
    font-feature-settings: "clig" off, "liga" off;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    align-self: stretch;
  }
</style>
